import React, { useState, useEffect } from "react";
import { Box } from "@chakra-ui/react";
import { useSpring, animated } from "@react-spring/web";
import { fetchData } from "./services/fetchers";
import Header from "./components/Header";
import MainSection from "./components/MainSection";
import Footer from "./components/Footer";
import Intro from "./components/Intro.js";
import bayanplus from "bayanplus-js";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  useDisclosure,
  CloseButton,
  useColorMode,
} from "@chakra-ui/react";

bayanplus.init({
  projectId: process.env.REACT_APP_BAYAN_PLUS_KEY,
  isDev: false,
});

function AppContent() {
  const {
    isOpen: isVisible,
    onClose,
    onOpen,
  } = useDisclosure({ defaultIsOpen: true });

  const [data, setData] = useState([]);
  const { colorMode } = useColorMode();
  const fontColor = colorMode === "light" ? "black" : "white";
  useEffect(() => {
    fetchData().then((fetchedData) => {
      setData(fetchedData);
    });
  }, []);

  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 1000 },
  });

  return (
    <Box pt="80px">
      {" "}
      {/* Ensure this matches your Header's actual height */}
      {isVisible && (
        <Box display="flex" justifyContent="center" alignItems="center" p={4}>
          <Alert
            status="success"
            borderRadius="lg"
            padding="1rem"
            fontSize="1.2rem"
          >
            <AlertIcon boxSize="2rem" mr={5} />
            <Box flex="1">
              <AlertTitle fontSize="1.5rem" color={fontColor}>
                بشارة! 🎊
              </AlertTitle>
              <AlertDescription lineHeight="1.5" color={fontColor}>
                وصلتنا الداتا الجديدة وكل الخريجين الحين يقدرون يستفيدون من
                الموقع
              </AlertDescription>
            </Box>
            <CloseButton
              alignSelf="flex-start"
              position="relative"
              right={-1}
              top={-1}
              onClick={onClose}
              color={fontColor}
            />
          </Alert>
        </Box>
      )}
      <animated.div style={style}>
        <Intro />
        <MainSection data={data} />
      </animated.div>
    </Box>
  );
}

function App() {
  return (
    <Box minH="100vh" color="black">
      <Header />
      <AppContent />
      <Footer />
    </Box>
  );
}

export default App;
