import React, { useState, useEffect } from "react";
import { Box, Image, IconButton, useColorMode } from "@chakra-ui/react";
import { FaMoon, FaSun } from "react-icons/fa";
import Logo from "../assets/YB_LogoText_Green.png";

const Header = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setHasScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box
      as="header"
      w="100%"
      p={4}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      bg={hasScrolled ? (colorMode === 'light' ? 'whiteAlpha.800' : 'blackAlpha.800') : 'transparent'}
      backdropFilter={hasScrolled ? "blur(10px)" : "none"}
      position="fixed"
      top="0"
      zIndex="banner"
      boxShadow={hasScrolled ? "sm" : "none"}
      transition="all 0.3s ease-in-out" // Smoothly transition all changes
    >
      <Image src={Logo} alt="YB Logo" htmlWidth="120px" />
      <IconButton
        aria-label="Toggle color mode"
        icon={colorMode === 'light' ? <FaMoon /> : <FaSun />}
        onClick={toggleColorMode}
        size="md"
        isRound={true}
      />
    </Box>
  );
};

export default Header;
