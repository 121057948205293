import React from 'react';
import { Box, useColorModeValue } from '@chakra-ui/react';
import { animated, useSpring } from '@react-spring/web';

const Loading = () => {
  // Animation for the bouncing effect
  const bouncing = useSpring({
    from: { transform: 'translateY(0px)' },
    to: { transform: 'translateY(-20px)' },
    loop: true,
    reverse: true,
    delay: 200,
    config: { duration: 500 },
  });

  // Determining background color based on color mode
  const bgColor = useColorModeValue('rgba(255, 255, 255, 0.8)', 'rgba(0, 0, 0, 0.8)');

  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      display="flex"
      justifyContent="center"
      alignItems="center"
      backgroundColor={bgColor}
      backdropFilter="blur(5px)"
      zIndex="modal" // Use a high zIndex value to ensure it covers other content
    >
      <animated.div style={bouncing}>
        <span style={{ fontSize: '4rem' }}>🎓</span>
      </animated.div>
    </Box>
  );
};

export default Loading;
