import React from "react";
import { Box, Link, Text, Icon, useColorModeValue } from "@chakra-ui/react";
import { FaLinkedin, FaGithub } from "react-icons/fa"; // Import LinkedIn, GitHub, and Twitter icons
import { FaSquareXTwitter } from "react-icons/fa6";

const Footer = () => {
  const textColor = useColorModeValue("black", "white");

  return (
    <Box as="footer" w="100%" p={4} color="white" textAlign="center">
      <Text color={textColor}>
        صُنِع وصُمِم بكل 💙 من قِبل{" "}
        <Link
          href="https://www.linkedin.com/in/hadi-al-marzooq/"
          isExternal
          onClick={() => console.log("LinkedIn Clicked")} // Replace with your actual event tracking
        >
          هادي المرزوق <Icon as={FaLinkedin} mx="2px" />
        </Link>{" "}
        <Link
          href="https://github.com/HadiAlMarzooq"
          isExternal
          onClick={() => console.log("GitHub Clicked")} // Replace with your actual event tracking
        >
          <Icon as={FaGithub} mx="2px" />
        </Link>{" "}
        <Link
          href="https://x.com/red_hadi"
          isExternal
          onClick={() => console.log("X Clicked")} // Replace with your actual event tracking
        >
          <Icon as={FaSquareXTwitter} mx="2px" />
        </Link>
      </Text>

      <Text color={textColor} fontSize="sm" mt="3">
        © جميع الحقوق محفوظة للجنة الكتاب السنوي 2024
      </Text>
      <Text dir="ltr">
        <Link href="https://twitter.com/KFUPMYearbook" isExternal color={textColor}>
          <Icon as={FaSquareXTwitter} boxSize="4" mb={-1} mr={1} />
          @KFUPMYearbook
        </Link>
      </Text>
    </Box>
  );
};

export default Footer;
